.App {
  text-align: center;
  background-color: #141416;
  overflow: hidden;
}
.new-app {
  background-color: white;
}
.old-app {
  .ant-pagination {
    li {
      a {
        color: white !important;
        span {
          color: white !important;
        }
        span:hover {
          color: #df0885 !important;
        }
      }
      a:hover {
        color: #df0885 !important;
      }
      button:hover {
        color: #df0885 !important;
      }
      button {
        color: white !important;
      }
    }
    .ant-pagination-item-active {
      background-color: rgba(0, 0, 0, 0);
      a {
        color: #df0885 !important;
      }
    }
  }
}
.gater-6n {
  .list-item {
    .new-nft-card:nth-child(5n) {
      margin-right: 32px !important;
    }
    .new-nft-card:nth-child(6n) {
      margin-right: 0;
    }
  }
}
.list-item {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  position: relative;
  min-height: 100px;
  .load-data-box {
    width: 100%;
    height: calc(100% - 30px);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 100;
    justify-content: center;
  }
  .market-card {
    width: calc(20% - 24px);
    margin-right: 30px;
    border-radius: 8px;
    position: relative;
    // box-shadow: 0px 0px 5px #333;
    cursor: pointer;
    transition: 0.3s all;
    margin-bottom: 30px;
    overflow: hidden;
    background-color: #23262f;
    .play-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      border-radius: 16px 0px 16px 0px;
      align-items: center;
      justify-content: center;
      span {
        font-size: 20px;
      }
    }
    .img-box {
      width: 100%;
      height: 220px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 16px;
      position: relative;
      .load-box {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 100%;
      }
    }
    .card-oper {
      padding: 16px;
      .name-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price-box {
          padding: 4px 8px;
          font-size: 12px;
          border-radius: 13px;
          border: 1px solid #ff5c92;
          color: #ff5c92;
        }
      }
    }
    .card-type {
      font-size: 20px;
      color: #666;
      text-align: left;
    }
    .other-msg {
      display: flex;
      color: white;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      position: relative;
      .up-top {
        position: absolute;
        top: -260px;
        width: 92px;
        height: 30px;
        right: -10px;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 0;
        border-radius: 20px;
      }
      p {
        font-size: 16px;
      }
      p:first-child {
        font-weight: 600;
      }
    }
  }
  .market-card:nth-child(5n) {
    margin-right: 0;
  }
  .market-card:hover {
    transform: translateY(-5px);
    // box-shadow: 0px 0px 10px rgba(247, 15, 255, 0.5);
  }
}
@media screen and (max-width: 1280px) {
  .list-item {
    .market-card {
      width: calc(50% - 12px) !important;
      margin-right: 24px !important;
      .img-box {
        height: 100px;
        border-radius: 8px;
      }
      .name-price {
        flex-direction: column;
        align-items: flex-start !important;
        .price-box {
          margin-top: 8px;
        }
      }
      .play-btn {
        right: -4px;
      }
      .card-type {
        font-size: 16px !important;
      }
      .other-msg {
        flex-direction: column;
        align-items: flex-start !important;
        p {
          font-size: 12px !important;
          margin-bottom: 8px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    .market-card:nth-child(2n) {
      margin-right: 0 !important;
    }
    .market-card:nth-child(5n) {
      margin-right: auto !important;
    }
  }
}
.fixed-price-inner {
  padding-top: 20px;
  .sell-nft {
    text-align: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 16px;
    .nft-box {
      display: flex;
      .p-1 {
        width: 108px;
        height: 108px;
        border: 1px solid #eee;
        overflow: hidden;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      .nft-info {
        flex: 1;
        margin-left: 24px;
        display: flex;
        justify-content: space-between;
        .p-inf {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          p:first-child {
            font-size: 16px;
            font-family: SparkSemiBold;
          }
        }
        .inf-right {
          align-items: flex-end;
        }
      }
    }
    .nft-name {
      font-size: 16px;
      color: #777e90;
      font-weight: 500;
      margin: 12px 0;
    }
    .token-id {
      font-size: 15px;
      color: #24262f;
    }
  }
  .label {
    color: #666;
    font-size: 16px;
  }
  .n-t {
    margin-top: 24px;
  }
  .set-duration {
    display: flex;
    margin-top: 8px;
    .date-box {
      height: 40px;
      border-radius: 8px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
    .left-end-date {
      width: 130px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      p {
        font-size: 15px;
        font-family: SparkSemiBold;
      }
      span {
        font-size: 12px;
        transition: 0.3s all;
      }
    }
    .show-pop {
      span {
        transform: rotate(180deg);
      }
    }
    .right-start-date {
      flex: 1;
      border: 0;
      .ant-picker {
        .ant-picker-input {
          input {
            font-size: 15px;
            font-family: SparkSemiBold;
          }
        }
      }
    }
  }
  .sale-msg {
    margin-top: 16px;
    li {
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      border-bottom: 1px solid #eee;
      p {
        font-size: 16px;
      }
      p:last-child {
        font-family: SparkSemiBold;
      }
    }
    li:last-child {
      border-bottom: 0;
      p {
        font-family: SparkSemiBold;
      }
    }
  }
  .price-text {
    text-align: center;
    margin-top: 24px;
    color: #df0885;
    font-size: 24px;
    font-weight: bold;
  }
  .inp-and-coin {
    display: flex;
    margin-top: 8px;
    input {
      height: 40px;
      border-radius: 8px;
      border: 1px solid #ccc;
      flex: 1;
      margin-right: 8px;
      padding-left: 12px;
      font-size: 16px;
    }
    .coin-select {
      width: 110px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #ccc;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      p {
        text-align: center;
        padding: 0 8px;
        font-size: 15px;
        font-family: SparkSemiBold;
      }
      span {
        color: #666;
      }
    }
  }
  .remark {
    font-size: 14px;
    color: #777e90;
    margin-top: 8px;
  }
  .submit-btn {
    text-align: center;
    margin-top: 40px;
    padding-bottom: 10px;

    .btns-oper {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    button {
      width: 160px;
      height: 40px;
      background-color: #df0885!important;
      border-radius: 20px;
      font-size: 16px;
      color: white !important;
      font-weight: 600;
      i {
        background-color: white;
      }
    }
    .disable-btn {
      background-color: #ccc!important;
    }
    .disable-btn:hover{
      background-color: #ccc!important;
    }
    .p-line {
      width: 60%;
      margin: 0 auto;
      margin-top: 24px;
      height: 4px;
      border-radius: 4px;
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .point {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ccc;
      }
      .start {
        background-color: #df0885;
      }
    }
    .pass-line {
      background-color: #df0885;
      .end {
        background-color: #df0885;
      }
    }
  }
}
.buy-inner {
  .sell-nft {
    .nft-box {
      width: 160px;
      height: 160px;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}
.up-mask {
  position: relative;
  z-index: 300;
}
.ant-modal-mask {
  z-index: 3000 !important;
}
.ant-modal-wrap {
  z-index: 3500 !important;
}
.ant-message {
  z-index: 4000 !important;
}
.ant-popover {
  z-index: 4000 !important;
}
.token-list {
  // width: 76px;
  ul {
    li {
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 4px 8px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 12px;
      }
      p {
        transition: 0.3s all;
        margin-right: 20px;
        font-size: 16px;
      }
    }
    .active-token {
      color: #df0885;
    }
    li:hover {
      background-color: #eee;
      p {
        color: #df0885;
      }
    }
    li:last-child {
      margin-bottom: 0;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
@keyframes Ele1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes Ele2 {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(0.8);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
@keyframes Ele3 {
  0% {
    transform: rotate(0) translateY(0) scale(1);
  }
  50% {
    transform: rotate(30deg) translateY(20px) scale(0.9);
  }
  100% {
    transform: rotate(0) translateY(0) scale(1);
  }
}
@keyframes Ele4 {
  0% {
    transform: rotate(0) translateY(0) scale(1);
  }
  50% {
    transform: rotate(-30deg) translateY(20px) scale(0.9);
  }
  100% {
    transform: rotate(0) translateY(0) scale(1);
  }
}
@keyframes Ele5 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}
.mask-element {
  .public-mask-ele {
    position: absolute;
  }
  .ele-1 {
    width: 200px;
    height: 200px;
    left: -60px;
    top: 680px;
    animation: Ele1 5s infinite;
  }
  .ele-2 {
    width: 136px;
    height: 136px;
    top: 980px;
    left: 130px;
    animation: Ele2 3s infinite;
  }
  .ele-3 {
    width: 164px;
    height: 164px;
    left: 0;
    top: 100px;
    animation: Ele3 3s infinite;
  }
  .ele-4 {
    width: 48px;
    height: 48px;
    top: 120px;
    right: 0;
    animation: Ele4 4s infinite;
  }
  .ele-5 {
    width: 100px;
    height: 100px;
    bottom: 10px;
    right: 300px;
    animation: Ele5 10s infinite;
  }
  .ele-6 {
    width: 300px;
    height: 300px;
    top: 800px;
    right: 0;
    animation: Ele1 4s infinite;
  }
  .ele-7 {
    width: 80px;
    height: 80px;
    top: 300px;
    right: 170px;
    animation: Ele2 3s infinite;
  }
}
.footer-wapper-new {
  padding: 80px 140px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-msg {
    text-align: left;
    img {
      width: 124px;
      height: 32px;
    }
    p {
      margin-top: 24px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
  .right-account {
    ul {
      display: flex;
      align-items: center;
      li {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 16px;
        border: 2px solid rgba(0, 0, 0, 0.4);
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 20px;
          color: #000;
        }
      }
    }
  }
}
.loading-box-public {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1280px) {
  .fixed-price-inner {
    .sell-nft {
      .nft-box {
        flex-direction: column;
        .p-1 {
          margin: 0 auto;
        }
        .nft-info {
          margin-left: 0;
          margin-top: 1.6rem;
          p {
            font-size: 1.4rem !important;
          }
        }
      }
    }
    .label {
      font-size: 1.4rem;
    }
    .inp-and-coin {
      input {
        height: 3.2rem;
      }
    }
    .set-duration {
      .date-box {
        height: 32px;
      }
      .left-end-date {
        p {
          font-size: 1.4rem;
        }
      }
      .right-start-date {
        .ant-picker {
          .ant-picker-input {
            input {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    .submit-btn {
      button {
        width: 12rem;
      }
    }
  }
  .footer-wapper-new {
    padding: 40px 0;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #ddd;
    .left-msg {
      text-align: center;
      img {
        width: 80px;
        height: 20px;
      }
      p {
        margin-top: 12px;
        font-size: 12px;
      }
    }
    .right-account {
      margin-top: 24px;
      ul {
        li {
          width: 32px;
          height: 32px;
          border-radius: 12px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}
w3m-modal {
  z-index: 5000;
}
.ant-picker-dropdown {
  z-index: 4050 !important;
}
