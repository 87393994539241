body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-family: Kanit;
  /* min-width: 1440px; */
}

html {
  font-size: 62.5%;
}

/* @media screen and (max-width: 1280px) {
  audio {
    display: none;
  }
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KanitBold';
  src: url('./assets/fonts/Kanit-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KanitLight';
  src: url('./assets/fonts/Kanit-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spark';
  src: url('./assets/fonts/Spark.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SparkTitleNew';
  src: url('./assets/fonts/Urbanist-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SparkTextNew';
  src: url('./assets/fonts/Poppins-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SparkSemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SparkBold';
  src: url('./assets/fonts/Poppins-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

.ant-message {
  z-index: 2000;
}

.loading-route {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.loading-box p {
  color: white;
  margin-left: 12px;
}

.ant-divider-inner-text {
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.ant-popover {
  z-index: 3000;
}

button {
  font-family: Kanit !important;
}

.ant-btn-primary:disabled {
  border-color: rgba(0, 0, 0, 0);
  background-color: #e10185;
  color: white;
}

.ant-checkbox-wrapper span {
  font-family: SparkTextNew;
}

.ant-radio-wrapper span {
  font-family: SparkTextNew;
}

button span {
  font-family: SparkTextNew;
}

input {
  font-family: SparkTextNew;
}
